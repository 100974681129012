
export default {
  name: 'BielbitMainDropDownItem',
  props: {
    positionHeading: {
      type: String,
      required: true,
    },
    positionDescription: {
      type: String,
      required: true,
    },
  },
}
