export const BielbitCard = () => import('../../components/BielbitCard.vue' /* webpackChunkName: "components/bielbit-card" */).then(c => wrapFunctional(c.default || c))
export const BielbitDropDownItem = () => import('../../components/BielbitDropDownItem.vue' /* webpackChunkName: "components/bielbit-drop-down-item" */).then(c => wrapFunctional(c.default || c))
export const BielbitLogo = () => import('../../components/BielbitLogo.vue' /* webpackChunkName: "components/bielbit-logo" */).then(c => wrapFunctional(c.default || c))
export const BielbitMainDropDownItem = () => import('../../components/BielbitMainDropDownItem.vue' /* webpackChunkName: "components/bielbit-main-drop-down-item" */).then(c => wrapFunctional(c.default || c))
export const BootstrapIcon = () => import('../../components/BootstrapIcon.vue' /* webpackChunkName: "components/bootstrap-icon" */).then(c => wrapFunctional(c.default || c))
export const ButtonTo = () => import('../../components/ButtonTo.vue' /* webpackChunkName: "components/button-to" */).then(c => wrapFunctional(c.default || c))
export const CertumFiles = () => import('../../components/CertumFiles.vue' /* webpackChunkName: "components/certum-files" */).then(c => wrapFunctional(c.default || c))
export const CookiePopup = () => import('../../components/CookiePopup.vue' /* webpackChunkName: "components/cookie-popup" */).then(c => wrapFunctional(c.default || c))
export const DownloadTable = () => import('../../components/DownloadTable.vue' /* webpackChunkName: "components/download-table" */).then(c => wrapFunctional(c.default || c))
export const ExpandableImage = () => import('../../components/ExpandableImage.vue' /* webpackChunkName: "components/expandable-image" */).then(c => wrapFunctional(c.default || c))
export const ExternalLink = () => import('../../components/ExternalLink.vue' /* webpackChunkName: "components/external-link" */).then(c => wrapFunctional(c.default || c))
export const HelpdeskSoftware = () => import('../../components/HelpdeskSoftware.vue' /* webpackChunkName: "components/helpdesk-software" */).then(c => wrapFunctional(c.default || c))
export const HeroUnit = () => import('../../components/HeroUnit.vue' /* webpackChunkName: "components/hero-unit" */).then(c => wrapFunctional(c.default || c))
export const HeroUnitInsert = () => import('../../components/HeroUnitInsert.vue' /* webpackChunkName: "components/hero-unit-insert" */).then(c => wrapFunctional(c.default || c))
export const InsertTiles = () => import('../../components/InsertTiles.vue' /* webpackChunkName: "components/insert-tiles" */).then(c => wrapFunctional(c.default || c))
export const InternalLink = () => import('../../components/InternalLink.vue' /* webpackChunkName: "components/internal-link" */).then(c => wrapFunctional(c.default || c))
export const LinkTo = () => import('../../components/LinkTo.vue' /* webpackChunkName: "components/link-to" */).then(c => wrapFunctional(c.default || c))
export const MailTo = () => import('../../components/MailTo.vue' /* webpackChunkName: "components/mail-to" */).then(c => wrapFunctional(c.default || c))
export const NewsPopup = () => import('../../components/NewsPopup.vue' /* webpackChunkName: "components/news-popup" */).then(c => wrapFunctional(c.default || c))
export const NoConnectionToNews = () => import('../../components/NoConnectionToNews.vue' /* webpackChunkName: "components/no-connection-to-news" */).then(c => wrapFunctional(c.default || c))
export const OurTiles = () => import('../../components/OurTiles.vue' /* webpackChunkName: "components/our-tiles" */).then(c => wrapFunctional(c.default || c))
export const PageRenderer = () => import('../../components/PageRenderer.vue' /* webpackChunkName: "components/page-renderer" */).then(c => wrapFunctional(c.default || c))
export const PartnersCarousel = () => import('../../components/PartnersCarousel.vue' /* webpackChunkName: "components/partners-carousel" */).then(c => wrapFunctional(c.default || c))
export const PopupModal = () => import('../../components/PopupModal.vue' /* webpackChunkName: "components/popup-modal" */).then(c => wrapFunctional(c.default || c))
export const ScreenshotCarousel = () => import('../../components/ScreenshotCarousel.vue' /* webpackChunkName: "components/screenshot-carousel" */).then(c => wrapFunctional(c.default || c))
export const SectionContent = () => import('../../components/SectionContent.vue' /* webpackChunkName: "components/section-content" */).then(c => wrapFunctional(c.default || c))
export const SectionContentInsert = () => import('../../components/SectionContentInsert.vue' /* webpackChunkName: "components/section-content-insert" */).then(c => wrapFunctional(c.default || c))
export const SectionContentInsertMain = () => import('../../components/SectionContentInsertMain.vue' /* webpackChunkName: "components/section-content-insert-main" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const StickyFooter = () => import('../../components/StickyFooter.vue' /* webpackChunkName: "components/sticky-footer" */).then(c => wrapFunctional(c.default || c))
export const TelTo = () => import('../../components/TelTo.vue' /* webpackChunkName: "components/tel-to" */).then(c => wrapFunctional(c.default || c))
export const UnderConstruct = () => import('../../components/UnderConstruct.vue' /* webpackChunkName: "components/under-construct" */).then(c => wrapFunctional(c.default || c))
export const WysiwygEditor = () => import('../../components/WysiwygEditor.vue' /* webpackChunkName: "components/wysiwyg-editor" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
