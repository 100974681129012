
import SEO from '~/mixins/SEO.js'

export default {
  name: 'ErrorPage',
  mixins: [SEO],
  layout: 'default',
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'], // you can set a custom layout for the error page
  data() {
    return {
      rawPageTitle: 'Wystąpił błąd',
      rawPageDescription: 'Strona błędu',
    }
  },
}
