export default {
  data() {
    return {
      baseUrl: this.$config.website.baseUrl,
      routePath: this.$route.path,
      test: 'ABC',
      what: 'strony',
    }
  },
  methods: {
    stripHtmlTagsFromText(text) {
      return text.replace(/<\/?[^>]+>/gi, '').replace('\n', '')
    },
  },
  computed: {
    computedPageTitle() {
      return this.rawPageTitle.replace(/<\/?[^>]+>/gi, '').replace('\n', '')
    },
    computedPageDescription() {
      return this.rawPageDescription
        .replace(/<\/?[^>]+>/gi, '')
        .replace('\n', '')
    },
    companyName() {
      return this.$config.website.companyName
    },
    computedTitle() {
      // eslint-disable-next-line no-irregular-whitespace
      const title = `${this.computedPageTitle}   |   ${this.companyName}`
      return title.length > 65
        ? title.replace(this.companyName, 'BIELbit')
        : title
    },
    computedImageTitle() {
      return `grafika dla ${this.what} ${this.computedPageTitle}`
    },
    currentPath() {
      return this.$config.website.baseUrl + this.routePath // tutaj musi być this.$config.website.baseUrl zamiast this.baseUrl, bo this.baseUrl może być w późniejszym czasie nadpisany
    },
    isBlog() {
      const articlePagePrefix = '/aktualnosci'
      return (
        this.routePath.indexOf(articlePagePrefix) === 0 &&
        this.routePath.length > articlePagePrefix.length + 1
      )
    },
    baseUrlSplit() {
      return this.baseUrl.split(':') // https://www.bielbit.pl
    },
    protocol() {
      return this.baseUrlSplit[0]
    },
    hostname() {
      return this.baseUrlSplit[1]
    },
  },
  head() {
    const head = {
      title: this.computedTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.computedPageDescription,
        },
        {
          hid: 'robots',
          name: 'robots',
          content: `index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1`,
        },
        {
          hid: 'author',
          name: 'author',
          content: `${this.companyName}`,
        },
        {
          hid: 'copyright',
          name: 'copyright',
          content: `${this.companyName}`,
        },
        {
          hid: 'publisher',
          name: 'publisher',
          content: `${this.companyName}`,
        },
        // Twitter Card
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.computedTitle,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.computedPageDescription,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: `${this.baseUrl}${this.heroUnitImg}`,
        },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          content: `${this.currentPath}`,
        },
        // Open Graph
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.computedTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.computedPageDescription,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: this.isBlog ? 'article' : 'website',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${this.baseUrl}${this.heroUnitImg}`,
        },
        {
          hid: 'og:secure_url ',
          property: 'og:secure_url ',
          content: `${this.baseUrl}${this.heroUnitImg}`,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${this.currentPath}`,
        },
        // itemprops
        {
          itemprop: 'name',
          content: this.computedTitle,
        },
        {
          itemprop: 'description',
          content: this.computedPageDescription,
        },
        {
          itemprop: 'url',
          content: `${this.currentPath}`,
        },
        {
          itemprop: 'image',
          content: `${this.baseUrl}${this.heroUnitImg}`,
        },
        {
          itemprop: 'thumbnailUrl',
          content: `${this.baseUrl}${this.heroUnitImg}`,
        },
      ],
      link: [
        {
          rel: 'image_src',
          href: `${this.baseUrl}${this.heroUnitImg}`,
        },
        {
          rel: 'canonical',
          href: `${this.currentPath}`,
        },
      ],
    }

    if (this.isBlog === true)
      head.meta.push(
        {
          hid: 'article:published_time',
          property: 'article:published_time',
          content: this.post.date,
        },
        {
          hid: 'article:modified_time',
          property: 'article:modified_time',
          content: this.post.modified,
        },
        {
          hid: 'article:author',
          property: 'article:author',
          content: this.authorName,
        },
      )

    // if (['/aktualnosci', '/aktualnosci/'].includes(this.routePath))
    //   head.link.push({
    //     rel: 'preload',
    //     as: 'fetch',
    //     href: `https://blog.bielbit.pl/wp-json/wp/v2/posts?page=1&per_page=4`,
    //   })

    if (this.keywords && this.keywords.length > 0)
      head.meta.push({
        hid: 'keywords',
        name: 'keywords',
        content: `${this.keywords.join(', ')}`,
      })

    return head
  },
}
