export default function (ctx) {
  const statusCode = 301 // na razie tymczasowe o kodzie 302, ale produkcyjnie będziemy używać kodu 301
  const fullPath = ctx.route.fullPath
  const path = ctx.route.path
  const replacedfullPath = fullPath
    .replace(/-\d*\//gi, '/')
    .replace(/\/\?limitstart=\d*/gi, '/')
    .replace(/\/\?start=\d*/gi, '/')
    .replace('/index.php', '')
    .replace('/cms', '')
  try {
    if (
      ctx.route.path.indexOf('/aktualnosci/') === 0 &&
      ctx.route.path.length > '/aktualnosci/'.length
    )
      ctx.redirect(statusCode, '/aktualnosci/')
    if (
      !path.endsWith('/') &&
      !path.endsWith('.pdf') &&
      !path.endsWith('.html') &&
      !path.endsWith('.docx') &&
      !path.endsWith('.xlsx')
    )
      ctx.redirect(statusCode, path + '/')
    if (
      path === '/kadry-i-place/wysylka-dokumentow-pracowniczych-na-ich-maile/'
    )
      ctx.redirect(
        statusCode,
        path.replace('kadry-i-place', 'nasze-realizacje/bielbit-dla-biznesu'),
      )
    if (
      [
        '/inwentaryzacja-srodkow-trwalych/',
        '/navireo-erp-wersja-dla-budownictwa/',
        '/obsluga-rozliczenia-umow-z-cykliczna-platnoscia/',
        '/obsluga-zgloszen-serwisowych/',
        '/rabatowanie-i-obsluga-bonow-w-sklepach/',
        '/rozbudowany-modul-zamowien/',
      ].includes(path)
    )
      ctx.redirect(statusCode, '/sprzedaz-i-magazyny' + fullPath)
    if (
      [
        '/rozszerzenie-dla-modulu-fk-navireo/',
        '/wydruk-pieczeci-dekretacji/',
      ].includes(path)
    )
      ctx.redirect(statusCode, '/ksiegowosc-i-finanse' + fullPath)
    if (
      [
        '/fundusz-mieszkaniowo-remontowy/',
        '/fundusz-swiadczen-socjalnych/',
        '/import-indywidualnych-kalendarzy-czasu-pracy/',
        '/kartoteka-pracownika/',
        '/kasa-zapomogowo-pozyczkowa/',
        '/modul-rozliczen-z-pracownikami/',
        '/obsluga-deklaracji-pfron/',
        '/obsluga-ubezpieczen-grupowych-pracownikow/',
        '/roczna-ewidencja-czasu-pracy-pracownika/',
        '/rozliczenie-godzin-pracownika/',
        '/wysylka-dokumentow-pracowniczych-na-ich-maile/',
      ].includes(path)
    )
      ctx.redirect(statusCode, '/kadry-i-place' + fullPath)
    if (fullPath === '/index.php/podpis-elektroniczny/')
      ctx.redirect(statusCode, '/podpis-elektroniczny/')
    if (fullPath === '/kontakt-2/') ctx.redirect(statusCode, replacedfullPath)
    if (fullPath === '/sitemap_index.xml/')
      ctx.redirect(statusCode, '/sitemap.xml')
    if (fullPath === '/epodpis-2/certum-cennik-uproszczony-od-29-02-2020/')
      ctx.redirect(
        statusCode,
        ctx.$config.website.baseUrl +
          '/pliki/certum-cennik-uproszczony.pdf?_=' +
          Date.now(),
      )
    if (fullPath === '/porownanie_produktow_gt_nexo_proctvnexo/')
      ctx.redirect(
        statusCode,
        ctx.$config.website.baseUrl +
          '/pliki/Porownanie_produktow_GT_NEXO_PROctvnexo.pdf?_=' +
          Date.now(),
      )
    if (
      fullPath.indexOf('/wp-content/uploads/') === 0 &&
      fullPath.includes('CERTUM-Cennik-uproszczony') !== true &&
      fullPath.includes('bb_') !== true
    ) {
      const elements = fullPath.split('/')
      const file = elements[elements.length - 1]
      ctx.redirect(statusCode, ctx.$config.website.baseUrl + '/pliki/' + file)
    }
    if (
      fullPath.indexOf('/nexo/pliki/') === 0 ||
      fullPath.indexOf('/navireo/pliki/') === 0
    ) {
      const elements = fullPath.split('/')
      const product = elements[1]
      const file = elements[elements.length - 1]
      ctx.redirect(
        statusCode,
        ctx.$config.website.baseUrl +
          '/insert/' +
          product +
          '/pobierz/pliki/' +
          file,
      )
    }
    if (
      fullPath.indexOf('/wp-content/uploads/') === 0 &&
      fullPath.includes('CERTUM-Cennik-uproszczony') === true
    )
      ctx.redirect(
        statusCode,
        ctx.$config.website.baseUrl +
          '/pliki/CERTUM-Cennik-uproszczony-dla-Klient%C3%B3w-od-04-01-2022.pdf',
      )
    if (fullPath.includes('/bb_') === true) {
      if (fullPath.includes('ecp_') === true)
        ctx.redirect(statusCode, '/kadry-i-place/')
      else if (fullPath.includes('rachmistrz') === true)
        ctx.redirect(statusCode, '/ksiegowosc-i-finanse/')
      else if (
        fullPath.includes('magazyn') === true ||
        fullPath.includes('umowy') === true ||
        fullPath.includes('subiektgt') === true ||
        fullPath.includes('rabaty') === true
      )
        ctx.redirect(statusCode, '/sprzedaz-i-magazyny/')
      else ctx.redirect(statusCode, '/nasze-realizacje/')
    }
    const prevYears = [
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
    ]
    if (fullPath.indexOf('/certum-cennik-uproszczony') === 0)
      for (const element of prevYears)
        if (fullPath.includes(element) === true) {
          ctx.redirect(
            statusCode,
            ctx.$config.website.baseUrl +
              '/pliki/CERTUM-Cennik-uproszczony-dla-Klient%C3%B3w-od-04-01-2022.pdf',
          )
          break
        }
    if (
      (fullPath.indexOf('/pl/p/') === 0 ||
        fullPath.indexOf('/pl/c/') === 0 ||
        fullPath.indexOf('/pl/new/') === 0 ||
        fullPath.indexOf('/oferta-handlowa/') === 0 ||
        replacedfullPath.indexOf('/oferta-handlowa/') === 0) &&
      path.endsWith('/')
    )
      ctx.redirect(statusCode, '/sprzet-i-serwis/')
    if (fullPath.indexOf('/ofirmie/realizacje/') === 0)
      ctx.redirect(statusCode, '/')
    if (
      fullPath.indexOf('/index.php/kontakt/') === 0 ||
      fullPath.indexOf('/cms/kontakt/') === 0
    )
      ctx.redirect(statusCode, '/kontakt/')
    if (
      fullPath.indexOf('/index.php/biuro-rachunkowe/') === 0 ||
      fullPath.indexOf('/cms/biuro-rachunkowe/') === 0
    )
      ctx.redirect(statusCode, '/biuro-rachunkowe/')
    switch (replacedfullPath) {
      case '/>/':
      case '/%3E/':
      case '/12232/':
      case '/1451/':
      case '/1e/':
      case '/2/':
      case '/2e/':
      case '/2010/01/01/o-nas/':
      case '/3/':
      case '/4/':
      case '/2010/01/01/kompetencje/':
      case '/2010/01/01/kompetencje/feed/':
      case '/apenaremont_pilch_hudro/':
      case '/badura/badura/':
      case '/badura_klingspor_zus_paged/':
      case '/bez-nazwy/':
      case '/bielbit_32x32/':
      case '/bielbit_logo/':
      case '/bielbit_microsoft_partner/':
      case '/bielmec/':
      case '/bmm_bond_bekuplast/':
      case '/bmm_bond_formed/':
      case '/certified-letter/':
      case '/dialpol_kpr_kurek_pralpol_obsessive/':
      case '/e/':
      case '/firmagodnazaufania/':
      case '/gora/':
      case '/info/':
      case '/index.html/':
      case '/index.php/':
      case '/insertnawww/':
      case '/insert-partner/':
      case '/insertpartner/':
      case '/kompetencje/':
      case '/kompetencje/logo/':
      case '/microsoftpartner/':
      case '/microsoftteams-image_156/':
      case '/microsoftteams-image_161/':
      case '/ms-partner/':
      case '/ms-partner-letter/':
      case '/mspartner/':
      case '/o-firmie/':
      case '/o-firmie/firmagodnazaufania/':
      case '/referencje/':
      case '/start/':
        ctx.redirect(statusCode, '/')
        break

      case '/30_lat_bielbit/':
      case '/a/':
      case '/aktualnosc/':
      case '/bieg-fca/':
      case '/black_week_promocja/':
      case '/dobramarkapromoblog/':
      case '/feed/':
      case '/itchampionchippromoblog/':
      case '/kartka2020/':
      case '/kartka-2021_/':
      case '/kartka-wielkanoc/':
      case '/kartka-wielkanoc-2021/':
      case '/lato_kadrowych__klienci_aktualnosc/':
      case '/licencje_zdalne_koniec_promocji/':
      case '/majowe-wolne/':
      case '/nowe_ceny_abonamentow/':
      case '/promoinsert/':
      case '/wielkanoc-2018-small/':
      case '/wielkanoc2019/':
      case '/zostan_biurem_pro/':
        ctx.redirect(statusCode, '/aktualnosci/')
        break

      case '/duzy/':
      case '/ksiega-podatkowa/':
      case '/ksieaga-podatkowa/':
      case '/maly/':
      case '/obsluga-kadrowo-placowa/':
      case '/pelna-ksiegowosc/':
      case '/pelna-ksiegowosc/pelna-ksiegowosc/':
      case '/rozliczenia-z-us-i-zus/':
      case '/ryczalt/':
        if (fullPath !== '/biuro-rachunkowe')
          ctx.redirect(statusCode, '/biuro-rachunkowe/')
        break

      case '/k1/':
      case '/kariera/wdrozeniowiec/':
      case '/praca/':
      case '/programista-c/':
      case '/programista-c/c/':
      case '/programista-php/':
      case '/programista-php/php/':
      case '/programista-t-sql/':
      case '/programista-t-sql/tsql/':
      case '/wdrozeniowiec-systemow-informatycznych/':
      case '/wdrozeniowiec-systemow-informatycznych/wdrozeniowiec/':
        ctx.redirect(statusCode, '/kariera/')
        break

      case '/contact/':
      case '/glownekontakt/':
      case '/kotakt/':
        // case '/kontakt/':
        if (fullPath !== '/kontakt') ctx.redirect(statusCode, '/kontakt/')
        break

      case '/naszteam/':
        ctx.redirect(statusCode, '/o-nas/')
        break

      case '/badura/': // EXTRA
      case '/bielbit-jpk/': // EXTRA
      case '/bielbit-jpk_small_2/': // EXTRA
      case '/bielbit-jpk_strona_fiirmowa_small/': // EXTRA
      case '/eltek/': // EXTRA
      case '/eltek-2/': // EXTRA
      case '/eltek-4/':
      case '/eltek/eltek/': // EXTRA
      case '/eltek-4/eltek/': // EXTRA
      case '/eltek-4/eltek-3/':
      case '/ergobi/':
      case '/fca/':
      case '/fcaszor/':
      case '/icon/':
      case '/infokity_2/':
      case '/kolektor/':
      case '/logo_pfron/':
      case '/mosir/':
      case '/mzd/':
      case '/mzd-system-prezentacji-utrudnien-w-ruchu/':
      case '/nemak/':
      case '/pamar_zam_proplast/':
      case '/realizacje/':
      case '/rocznaecp_raport/':
      case '/rozwiazania/':
      case '/rozwiazania-dedykowane/':
      case '/rozwiazania-dedykowanie/':
      case '/swiatynia/':
      case '/system-ars/':
      case '/system-ars/ars2/':
      case '/szczyty/':
      case '/szor/':
      case '/terminal_kodydostaw-401x640/':
      case '/ulotka_utrudnienia_w_ruchu/': // EXTRA
      case '/windyk/':
      case '/nasze-realizacje/insert/':
      case '/rozszerzenia-navireo/193-kolektory-danych2/':
        // case '/sprzedaz-i-magazyny/':
        ctx.redirect(statusCode, '/nasze-realizacje/')
        break

      case '/wcm/': // EXTRA
      case '/wcm/mzd/': // EXTRA
      case '/wcm-2/':
      case '/wcm-3/':
        ctx.redirect(
          statusCode,
          '/nasze-realizacje/wcm-zintegrowany-system-zarzadzania/',
        )
        break

      case '/kadry-place/':
      case '/nasze-realizacje/insert/kadry-place/':
        ctx.redirect(statusCode, '/kadry-i-place/')
        break

      case '/ksiegowosc/':
      case '/nasze-realizacje/insert/ksiegowosc/':
        ctx.redirect(statusCode, '/ksiegowosc-i-finanse/')
        break

      case '/nasze-realizacje/insert/sprzedaz-i-magazyny/':
        ctx.redirect(statusCode, '/sprzedaz-i-magazyny/')
        break

      case '/mobilny-magazynier/':
      case '/nasze-realizacje/insert/mobilny-magazynier/':
        ctx.redirect(
          statusCode,
          '/nasze-realizacje/mobilny-magazynier-na-androida/',
        )
        break

      case '/obowiazek-informacyjny/':
      case '/obowiazek-informacyjny/rodo/':
      case '/obowiazek-informacyjny-firmy-bielbit-sp-z-o-o/':
        ctx.redirect(statusCode, '/obowiazek-informacyjny-firmy/')
        break

      case '/certum-cennik-uproszczony/':
      case '/e-podpis-oferta/':
        ctx.redirect(
          statusCode,
          ctx.$config.website.baseUrl +
            '/pliki/certum-cennik-uproszczony.pdf?_=' +
            Date.now(),
        )
        break

      case '/epodpis/certyfikatassecodatasystembielbitspzoo/': // EXTRA
      case '/certyfikatassecodatasystembielbit/': // EXTRA
      case '/epodpis/': // EXTRA
      case '/epodpis/epodpis_/':
      case '/epodpis-1/':
      case '/epodpis-2/':
      case '/epodpis-2/epodpis_/':
        ctx.redirect(statusCode, '/podpis-elektroniczny/')
        break

      case '/instrukcja-obslugi-klienta-poczty/':
      case '/instrukcja-obslugi-klienta-poczty-outlook/':
      case '/instrukcja-obslugi-klienta-poczty-outlook-2019/':
      case '/instrukcja-obslugi-poczty-bielbit/':
      case '/obsluga-dealerow-fca/':
      case '/pomoczdalna/':
        ctx.redirect(statusCode, '/pomoc-dla-partnerow/')
        break

      case '/1/':
      case '/3e/': // EXTRA
      case '/3e-2/':
      case '/jetpack/':
      case '/jetpack-5/':
      case '/jetpack-5-3/':
      case '/jetpack-5-3-2/':
      case '/pl/new/1/default/5/f_producer_191/1/':
      case '/sieci/':
      case '/sieci-komputerowe/':
      case '/uslugi-serwisowe/':
      case '/sieci-komputerowe/sieci_komputerowe/':
        ctx.redirect(statusCode, '/sprzet-i-serwis/')
        break

      case '/agralan-internetowa-platforma-dla-agrobiznesu/sharing_social_media/':
      case '/agralan-internetowa-platforma-dla-agrobiznesu/':
      case '/insert/agralan-internetowa-platforma-dla-agrobiznesu/':
        ctx.redirect(statusCode, '/insert/agralan/')
        break

      case '/gratyfikant/':
      case '/insert-gt/':
      case '/insert/gt/':
      case '/insert-gt/insert_logo_male/':
      case '/insert_logo/':
      case '/navi-pserp/':
        ctx.redirect(statusCode, '/insert/')
        break

      case '/insert-gt/subiekt_gt/':
      case '/subiektgt/':
        ctx.redirect(statusCode, '/insert/subiekt-gt/')
        break

      case '/gestorgt/':
      case '/insert-gt/gestor_gt/':
        ctx.redirect(statusCode, '/insert/gestor-gt/')
        break

      case '/insert-nexo/gratyfikant_nexo_pro_pudelko/':
        ctx.redirect(statusCode, '/insert/gratyfikant-nexo-pro/')
        break

      case '/gratyfikantgt/':
        ctx.redirect(statusCode, '/insert/gratyfikant-gt/')
        break

      case '/insert-gt/rachmistrz_gt/':
      case '/rachmistrzgt/':
        ctx.redirect(statusCode, '/insert/rachmistrz-gt/')
        break

      case '/insert-nexo/rachmistrz_nexo_pro_1_stanowisko_pudelko/':
      case '/insert-nexo/rachmistrz_nexo_pro_1_stanowisko_pudelko_maly/':
        ctx.redirect(statusCode, '/insert/rachmistrz-nexo-pro/')
        break

      case '/insert-nexo/subiekt_nexo_pro_1_stanowisko_pudelko/':
        ctx.redirect(statusCode, '/insert/subiekt-nexo-pro/')
        break

      case '/insert/subiekt123/':
        ctx.redirect(statusCode, '/insert/subiekt-123/')
        break

      case '/insert/nexo/':
      case '/insert-nexo/':
      case '/insertpromobloglarge_v2/':
        ctx.redirect(statusCode, '/insert/')
        break

      case '/navireo/':
        if (fullPath === '/navireo-4/')
          ctx.redirect(statusCode, '/insert/navireo/')
        else ctx.redirect(statusCode, '/navireo-pobierz/')
        break

      case '/formularz-zgloszen/':
      case '/navireo-pobierz/':
      case '/nexo-pobierz/':
        ctx.redirect(statusCode, '/pomoc-dla-partnerow' + path)
        break

      case '/navireo-erp/':
        ctx.redirect(statusCode, '/insert/navireo-erp/')
        break

      case '/uslugi-it/':
      case '/jakdzialamy_/':
        ctx.redirect(statusCode, '/oprogramowanie/')
        break

      case '/insert/navireo-erp/':
        ctx.redirect(statusCode, '/insert/navireo/')
        break

      case '/insert-nexo/gestor_nexo_pro_pudelko/':
        ctx.redirect(statusCode, '/insert/gestor-nexo-pro/')
        break

      case '/insert-nexo/subiekt_nexo_pro_1_stanowisko_pudelko_maly/':
        ctx.redirect(statusCode, '/insert/subiekt-nexo-pro/')
        break

      case '/insert-gt/rewizor_gt/':
      case '/rewizorgt/':
        ctx.redirect(statusCode, '/insert/rewizor-gt/')
        break

      case '/nexo/':
        ctx.redirect(statusCode, '/nexo-pobierz/')
        break

      case '/aktu_portalbiura_455_290/':
      case '/portal-biura/':
        ctx.redirect(statusCode, '/insert/portal-biura/')
        break

      case '/zarezerwuj_pl_pudelko/':
      case '/zarezerwuj_pl_pudelko_small/':
      case '/zarezerwuj-pl-system-rezerwacji-online/':
      case '/zarezerwuj-pl-system-rezerwacji-online/zarezerwuj_pl_pudelko_small/':
        ctx.redirect(
          statusCode,
          '/insert/zarezerwuj-pl-system-rezerwacji-online/',
        )
        break

      default:
        break
    }
    if (
      fullPath ===
      '/wp-includes/js/dist/vendor/wp-polyfill-url.min.js?ver=3.6.4'
    )
      ctx.redirect(statusCode, '/')
    if (path === '/index.php/') ctx.redirect(statusCode, '/')
    if (path === '/index.php/sprzedaz-i-magazyny/')
      ctx.redirect(statusCode, '/sprzedaz-i-magazyny/')
  } catch (e) {
    console.log(
      'Początek informacji o złapanym wyjątku związanym z przekierowaniem',
    )
    console.log('path:' + path)
    console.log('fullPath:' + fullPath)
    console.log('replacedfullPath:' + replacedfullPath)
    console.log(
      'Koniec informacji o złapanym wyjątku związanym z przekierowaniem',
    )
  }
}
