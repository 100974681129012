import WPApi from 'wpapi'

import * as WPApiExtensions from 'wpapi-extensions'

const options = {"sitemap":false,"endpoint":"https:\u002F\u002Fblog.bielbit.pl\u002Fwp-json","extensions":true}
let wp = new WPApi(options)

export default async (ctx, inject) => {
    WPApiExtensions.registerWuxt(wp)

  inject('wp', wp)
}
