import Vue from 'vue'
import VeeValidate from 'vee-validate'

import pl from 'vee-validate/dist/locale/pl'

Vue.use(VeeValidate, {
  "inject": true,
  "fieldsBagName": "veeFields",
  "errorBagName": "veeErrors"
})

VeeValidate.Validator.localize('pl', pl)

export default ({ app }, inject) => {
  app.validator = VeeValidate.Validator

  const getLocale = (opt, locale) => {
    if (typeof opt === 'object' && opt.locale[locale]) {
      return opt.locale[locale]
    }
    return locale
  }
  const nuxti18n = {"locale":{"pl-PL":"pl_PL"}}
  if (nuxti18n && app.i18n) {
    const validatorLocale = getLocale(nuxti18n, app.i18n.locale)
    if (app.validator.locale !== validatorLocale) {
      import(`vee-validate/dist/locale/${validatorLocale}`).then(dic => {
        app.validator.localize(validatorLocale, dic)
      })
    }

    const beforeLanguageSwitch = app.i18n.beforeLanguageSwitch
    app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
      beforeLanguageSwitch(oldLocale, newLocale)
      const newValidatorLocale = getLocale(nuxti18n, newLocale)
      import(`vee-validate/dist/locale/${newValidatorLocale}`).then(dic => {
        app.validator.localize(newValidatorLocale, dic)
      })
    };
  }
}
